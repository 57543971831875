<template>
    <div class="user-setting">
        <el-card class="box-card "  shadow="never">
            <div slot="header" class="clearfix">
                <span>账号设置</span>
            </div>
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <input type="file" v-if="showFileInput" ref="fileinput" style="opacity: 0;position: fixed;bottom: -100px"  accept="image/jpeg,image/jpg,image/png" @change="inputChange"/>
                <el-tab-pane label="基本信息" name="first">
                    <el-form ref="form" :model="form" label-width="80px">
                        <el-form-item label="">
<!--                            <img :src="$imgurl+form.avatar">-->
                            <el-image :src="$imgurl+form.avatar"  @click.native="upload" @error="imagerror">
                                <div slot="error" class="image-slot">
                                    <!--                              <i class="iconfont icon-biaoqiankuozhan_wode-135"></i>-->
                                    <img src="../../../assets/images/userimg.png" width="100"/>
                                    <div class="upload-text">点击上传</div>
                                </div>
                            </el-image>
                        </el-form-item>
                        <el-form-item label="用户名">
                           <span>{{user.username}}</span>
                        </el-form-item>
                        <el-form-item label="昵称">
                            <el-input autocomplete="new-password" type="text" v-model="form.nickname" style="width: 250px"></el-input>
                        </el-form-item>
                        <el-form-item label="生日">
                            <el-col :span="11">
                                <el-date-picker type="date" placeholder="选择日期" v-model="form.birthday" style="width: 250px"></el-date-picker>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="性别">
                            <el-radio-group v-model="form.gender">
                                <el-radio :label="1" :value="1">男</el-radio>
                                <el-radio :label="0" :value="0">女</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="个性签名">
                            <el-input autocomplete="new-password" type="textarea" v-model="form.bio" style="width: 250px"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="onSubmit">保存信息</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="密码设置" name="second">
                    <el-form label-position="right" label-width="0" :model="resetpwdForm" style="width: 30%;padding: 15px" v-if="user.mobile">
               <!--         <el-form-item label="">
                            <el-input autocomplete="new-password" placeholder="手机号码" type="number" :maxlength="11" v-model="resetpwdForm.mobile"></el-input>
                        </el-form-item>-->
                        <el-form-item >
                            <span>当前手机号码：{{user.mobile ? user.mobile : '未绑定'}}</span>
                        </el-form-item>
                      <el-form-item label="">
                        <el-input autocomplete="new-password" placeholder="请输入旧密码" show-password v-model="resetpwdForm.password"></el-input>
                      </el-form-item>
                        <el-form-item label="">
                            <el-input autocomplete="new-password" placeholder="请输入新密码" show-password v-model="resetpwdForm.newpassword"></el-input>
                        </el-form-item>
                        <el-form-item label="">
                            <el-input autocomplete="new-password" placeholder="请再次输入新密码" show-password v-model="resetpwdForm.againPassword"></el-input>
                        </el-form-item>
<!--                        <el-form-item label="">-->
<!--                            <el-row>-->
<!--                                <el-col :span="14">-->
<!--                                    <el-input autocomplete="new-password" placeholder="验证码" :maxlength="4" v-model="resetpwdForm.captcha"></el-input>-->
<!--                                </el-col>-->
<!--                                <el-col :span="7" @click.native="getMobileCode('resetpwd', user.mobile)">-->
<!--                                    <el-button style="margin-left: 5px" type="text">{{ times > 0 ? `${times}秒后获取`: '获取验证码'}}</el-button>-->
<!--                                    &lt;!&ndash;                            <el-image  style="height: 42px;width: 100%" :src="captchaUrl" fit="contain"></el-image>&ndash;&gt;-->
<!--                                </el-col>-->
<!--                            </el-row>-->


<!--                        </el-form-item>-->
                        <el-form-item>
                            <el-button type="primary" @click="resetpwd">确定修改</el-button>
                        </el-form-item>
                    </el-form>
                    <div v-else>您尚未绑定手机号码，请先绑定手机号码后再进行修改密码。</div>
                </el-tab-pane>
                <el-tab-pane label="绑定手机设置" name="third">
                    <el-form label-position="right" :model="mobileForm" style="width: 30%;padding: 15px">
                        <el-form-item label="当前手机号码:">
                          <span>{{user.mobile ? user.mobile : '未绑定'}}</span>
                        </el-form-item>
                        <el-form-item label="">
                            <el-input autocomplete="new-password" placeholder="新手机号码" type="number" :maxlength="11" v-model="mobileForm.mobile"></el-input>
                        </el-form-item>
                        <el-form-item label="">
                            <el-row>
                                <el-col :span="14">
                                    <el-input autocomplete="new-password" placeholder="验证码" :maxlength="4" v-model="mobileForm.captcha"></el-input>
                                </el-col>
                                <el-col :span="7" @click.native="getMobileCode('profile', mobileForm.mobile)">
                                    <el-button style="margin-left: 5px" type="text">{{ times > 0 ? `${times}秒后获取`: '获取验证码'}}</el-button>
                                    <!--                            <el-image  style="height: 42px;width: 100%" :src="captchaUrl" fit="contain"></el-image>-->
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="changemobile">确定修改</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
            </el-tabs>
        </el-card>

    </div>
</template>

<script>
    import {UserService} from "../../../api/user";
    import {sendcode, upload} from "../../../api/common";
    import {setTimeDown} from "../../../utils/storageUtils";
    import { mapGetters } from 'vuex'
    export default {
        name: "index",
        data() {
            return {
                activeName: 'first',
                form: {
                    nickname: '',
                    gender: '',
                    birthday: '',
                    bio: '',
                    avatar: '22',
                },
                resetpwdForm: {
                    password: '',
                    mobile: '',
                    newpassword: '',
                    againPassword: '',
                    captcha: '',
                },
                mobileForm: {
                    mobile: '',
                    captcha: ''
                },
                times: 0,
                showFileInput: true,
            };
        },
        computed:{
            ...mapGetters(['user']),
        },
        methods: {
            imagerror(e) {
                // console.log('e22', e)
            },
            resetpwd() {
                // if (!this.user.mobile) {
                //     this.$message.warning('请先绑定手机号码后再进行密码重置')
                //     return
                // }
                if (this.resetpwdForm.newpassword !== this.resetpwdForm.againPassword) {
                    this.$message.warning('两次密码不一致')
                    return
                }
                // this.resetpwdForm.mobile = this.user.mobile
                UserService.userResetpwd(this.resetpwdForm).then(async res => {
                    if (res.code) {
                        // this.getUser(res)
                        this.$message.success('重置密码成功')
                        setTimeout(() => {
                            window.location.reload()
                        }, 2000)
                    } else {
                        this.$message.error(res.msg)
                        // this.getCaptcha()
                    }
                })
            },
            changemobile() {

                UserService.changemobile(this.mobileForm).then(async res => {
                    if (res.code) {
                        // this.getUser(res)
                        this.$message.success('手机号码修改成功')
                        setTimeout(() => {
                            window.location.reload()
                        }, 2000)
                    } else {
                        this.$message.error(res.msg)
                        // this.getCaptcha()
                    }
                })
            },
            countdown() {
                this.times = 60
                // eslint-disable-next-line no-unused-vars
                const down = () => {
                    setTimeout(() => {
                        this.times--
                        setTimeDown(this.times)
                        if (this.times > 0) {
                            down()
                        }
                    }, 1000)
                }
                down()

            },
            getMobileCode(event, mobile) {
                if (!mobile && event === 'resetpwd') {
                    this.$message.warning('请先绑定手机号码后再进行密码重置')
                    return
                }
                if (this.times > 0 || !mobile) return
                sendcode({ event, mobile }).then(res => {
                    // // console.log('res', res)
                    if (res.code) {
                        this.$message.success('验证码发送成功')
                        this.countdown()
                    } else {
                        this.$message.error(res.msg)

                    }
                    // this.captchaUrl = res.data
                })
            },
            inputChange(e) {
                // // console.log('e', e)
                const forms = new FormData()
                const file = e.target.files[0]
                forms.append('file', file)
                upload(forms).then(res => {
                    // // console.log('res22', res)
                    this.form.avatar = res.data.url
                    if (res.code) {
                        this.form.avatar = res.data.url
                    } else {
                        this.$message.error(res.msg)
                    }
                })

                this.showFileInput = false
                setTimeout(() => {
                    this.showFileInput = true
                }, 100)
            },
            upload() {
                this.$refs.fileinput.click()
            },
            onSubmit() {
                UserService.profile(this.form).then(res => {
                    if (res.code) {
                        this.$message.success('修改成功')
                        this.$store.dispatch('getUserInfo')
                        setTimeout(() => {
                            window.location.reload()
                        }, 100)
                    } else {
                        this.$message.error(res.msg)
                    }

                })
            },
            handleClick(tab, event) {
                // // console.log(tab, event);
            }
        },
        created() {
            this.form = {...{}, ...this.user}
            // console.log('this.form', this.form)
        },
        watch: {
            user: {
                deep: true,
                handler() {
                    // console.log(222)

                    this.form = {...{}, ...this.user}
                    // this.mobileForm.mobile = this.user.mobile
                    // console.log('this.form22', this.form)
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .user-setting{
        .el-image{
            width: 100px;
            height: 100px;
            align-items: center;
            vertical-align: middle;
            text-align: center;
            /*line-height: 100px;*/
            border-radius: 50px;
            border: 1px solid $brand_color;
            margin-left: 50px;
            position: relative;
            cursor: pointer;
            .upload-text{
                position: absolute;
                top: 82px;
                left: -4px;
                text-align: center;
                background-color: #C0C4CC;
                color: white;
                width: 111px;
                opacity: 0.8;
                height: 27px;
                display: flex;
                align-items: center;
                flex-direction: column-reverse;
                font-size: 12px;
            }
        }
    }
</style>